.footer-analytics {
  height: 100%;
  width: 100%;

  padding: 32px;

  box-sizing: border-box;

  color: var(--text-color-white);
  background-color: var(--analytics-violet);

  z-index: 0;
}

.footer-analytics-container {
  height: 100%;
  width: 100%;
  max-width: 1024px;

  margin: auto;

  z-index: 1;
}

.footer-analytics .col-md-4 {
  width: 33.3333%;
}

.footer-analytics .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: auto;
}

.footer-analytics .col-md-12 {
  width: 50%;
}
