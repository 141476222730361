.summary {
  &__screen-time {
    &__info-top-left {
      &--title {
        font-size: 0.8rem;
        font-weight: bold;
      }

      &--subtitle {
        font-size: 0.7rem;
        font-weight: normal;
      }
    }
  }

  &__activities {
    &__info-bot {
      display: flex;
      align-items: center;

      img {
        margin-right: 4px;
      }
    }
  }

  &__skill-map {
    &__info-top-left {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }

  &__recommendation {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin-bottom: 8px;

    p {
      font-size: 0.8rem;
    }

    img {
      width: 16px;
      height: 16px;

      margin-right: 12px;
    }

    &__subtitle {
      font-size: 0.8rem;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }

  &__history {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
