.titleswitch__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1rem auto;

  &__name {
    margin-left: 2rem;

    font-size: 0.8rem;
    &--title {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }

  &__switch {
    .react-switch-handle {
      height: 14px !important;
      width: 14px !important;
    }
  }
}
