.recommendation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
  }

  &__container {
    position: relative;
    width: 80%;
    max-width: 600px;
    background: white;
    border-radius: 10px;
    overflow: visible;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem;

    font-weight: bold;

    border-radius: 10px;
    background-color: #f4f4f4;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-evenly;

      width: auto;
      height: 100%;

      color: white;
      font-weight: bold;

      background-color: #909090;

      border: none;
      border-radius: 25px;

      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        // background-color: red;
      }

      img {
        width: 38px;
        height: 38px;
      }
    }

    &__close {
      position: absolute;
      top: -30px;
      right: -30px;
      background: transparent;
      border: none;
      padding: 0.5rem;
      cursor: pointer;

      img {
        width: 50px;
        height: 50px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__infos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem 0.5rem 1.5rem;

    img {
      width: 60px;
      height: 60px;
      margin-right: 1rem;
    }

    &--title {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 4px;
    }

    &--subtitle {
      font-size: 1rem;
    }
  }

  &__choices {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;

    &--suggest {
      background-color: #823d90;
    }

    &--impose {
      background-color: #ce1719;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-evenly;

      width: 45%;
      height: 100%;

      color: white;

      border: none;
      border-radius: 10px;

      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        // background-color: red;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
