.double-detail-card {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left, &__right {
    width: 48%;
  }

  .detail-card {
    margin: 4px 0;
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: var(--analytics-card-background);

    &__extra-component {
      margin-bottom: 16px;
    }

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
    }

    &__icon-container--left,
    &__icon-container--right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      column-gap: 8px;
      max-width: 30%;
      z-index: 10;
    }

    &__icon-container--left {
      left: 0;
      justify-content: flex-start;
    }

    &__icon-container--right {
      right: 0;
      justify-content: flex-end;
    }

    &__title {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
      font-weight: bold;
    }

    &__subtitle {
      margin: 16px 0;
      color: var(--analytics-violet);
      font-size: 1rem;
    }

    &__content {
      margin: 10px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}