.profile-selector-pc {
  background: var(--analytics-violet);

  width: 192px;
  height: 192px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .top-section {
    width: 100%;
    height: 66%;

    display: flex;
    justify-content: space-evenly; // répartition équilibrée des enfants
    align-items: center; // centre vertical

    .avatar {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0;
    }

    .arrow-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .mid-section {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 8%;

    margin-bottom: 24px;

    .profile-username {
      font-size: 18px;
      color: #fff;
      text-align: center;
    }
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 16%;

    .tick-icon {
      width: 15px;
      height: 15px;
      object-fit: cover;

      margin-right: 12px;
    }

    span {
      font-size: 16px; // à ajuster selon votre besoin
      color: #fff; // ou la couleur appropriée du texte "Connecté"
    }

    .logout-btn {
      background: none;
      border: none;
      cursor: pointer;
      width: 15px;
      height: 15px;
      padding: 0; // Aucun padding
      overflow: hidden; // Masque tout ce qui dépasse

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
