.day-counter {
  display: flex;
  align-items: center;
  width: 80%;
  //   padding: 10px;

  .day-name {
    flex: 1;
    text-align: left;
  }

  .count {
    padding: 0 10px;
  }
}
