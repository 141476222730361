.auth__analytics-settings-security {
  flex: 1;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  &__child-list {
    margin-bottom: 16px;

    &--container {
      display: flex;
      margin: 8px 0;

      button {
        margin-left: 8px;
        padding: 0.5rem 0.5rem;
        border: none;
        border-radius: 5px;
        color: var(--text-color-white);
        background-color: rgb(211, 28, 0);
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    margin: 8px 0;
    font-size: 0.9rem;
    font-weight: bold;
  }

  &__input {
    width: 97%;
    margin-left: 8px;
    padding: 4px;
    border: 1px solid #707070;
    border-radius: 8px;
    line-height: 1rem;
    font-size: 0.8rem;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }
  }

  &__password-error,
  &__error {
    color: #df0000;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 4px 0 0;
    text-align: center;
  }

  &__password-submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      width: auto;
      padding: 0.5rem 2rem;
      border-radius: 5px;
      border: 2px solid white;
      font-weight: bold;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .disabled {
      color: gray;
      background-color: lightgray;
      cursor: not-allowed;
    }

    .enabled {
      color: var(--text-color-white);
      background-color: var(--analytics-violet);
      cursor: pointer;
    }
  }
}

.auth__analytics-settings-security__input--error {
  border: 2px solid rgb(255, 99, 99);
}
