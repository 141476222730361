.validation-page {
  flex: 1;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: calc(100% - 24px);

    max-width: 65vw;

    margin: 10vh auto;
    padding: 32px 24px;

    border-radius: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;

    background-color: #e4e4e4;
  }

  &__container-inputs {
    width: 100%;
  }

  &__select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      flex: 1 1;
      width: 100%;
      margin: 0 8px;
    }
  }

  &__day-counters {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__form-container {
    > div:nth-child(2) {
      margin-top: 64px;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    &--password {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &--username {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &--mail {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__input {
    width: 97%;

    margin: 8px 0;
    padding: 8px 4px;

    border: 1px solid #707070;
    border-radius: 8px;

    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }
  }

  &__error-message {
    color: rgb(219, 0, 0);
    margin-top: 24px;
  }

  &__submit-button {
    width: 100%;

    margin: 24px auto 0 auto;
    padding: 12px;

    border: none;
    border-radius: 8px;

    color: white;
    font-size: 1rem;

    cursor: pointer;
    transition: background-color 0.3s ease;

    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &__submit-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 12px;
    padding: 8px;

    border: 2px white solid;
    border-radius: 5px;

    color: black;

    background-color: white;

    overflow: auto;

    &--error {
      border: 2px rgb(139, 0, 0) solid;
      background-color: rgb(139, 0, 0);
    }
  }

  &__birthday-inputs {
    justify-content: space-evenly;
  }

  &__gender-inputs {
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }
  }

  &__gender-choice {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    height: 100%;
    width: 100%;

    margin: 16px 0;
  }

  &__child-choice {
    height: 100%;
    width: 100%;

    img {
      height: 36px;
      width: 36px;
    }
  }

  &__label-child-choice {
    flex: 1;
    text-align-last: center;

    transition: transform 0.3s ease;

    border-radius: 35px;

    &--selected {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      transform: scale(1.1);

      color: var(--primary-color);
      font-weight: bold;

      background-color: #fff;
      border-radius: 8px;

      padding: 8px 16px;
    }

    &--not-selected {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      transform: scale(1);

      color: #333;
      font-weight: normal;
    }
  }

  @media (max-width: 980px) {
    &__container {
      width: calc(100% - 24px);
      max-width: 80vw;
      padding: 32px 12px;

      margin: 7vh auto;
    }
  }

  @media (max-width: 780px) {
    &__select-container {
      flex-direction: column;

      width: 90%;
    }

    &__form-container {
      flex-direction: column;

      width: 90%;
    }
  }
}
