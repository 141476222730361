button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.settings-sleep-mode {
  .explanatory-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;

    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.4;
    max-width: 500px;
  }
  
  .child-sleep-mode {
  }

  .child-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-radius: 4px;
  }

  .child-name {
    flex-grow: 1;
    text-align: left;
    font-size: 0.9rem;

  }

  .child-arrow {
    font-size: 0.7rem;
    text-align: right;
  }

  .sleep-schedule {
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    position: relative;
  }

  .delete-button {
    position: absolute;
    top: -11px;
    right: -13px;
    background: #823d90;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100%;
    border: 1px solid #ccc;
    color: white;
  }

  .add-button {
    display: block;
    margin: 10px auto;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
  }

  .time-selection {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      margin-right: 5px;
      font-size: 0.7rem;
      white-space: nowrap;
    }

    input[type="time"] {
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 0.7rem;

    }
  }

  .day-selection {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
      flex: 1;
      margin: 0 2px;
      padding: 5px 0;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: beige;
      cursor: pointer;

      &.active {
        background-color: var(--analytics-violet);
        color: white;
      }
    }
  }
}
