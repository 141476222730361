.profile-selector-mobile {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  height: 130px;

  margin: 0 auto;

  box-sizing: border-box;

  overflow: hidden;

  .top-section {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 66%;

    margin-top: -6px;

    .avatar {
      width: 72px;
      height: 72px;

      margin: 4px;

      border-radius: 50%;
      object-fit: cover;
    }

    .arrow-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .bot-section {
    width: 100%;

    .profile-username {
      font-size: 24px;
      font-weight: bold;
      color: var(--analytics-violet);
      text-align: center;
    }
  }

  &.lite {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .lite-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
    }

    .avatar {
      width: 48px;
      height: 48px;

      border-radius: 50%;
      object-fit: cover;
    }

    .profile-username {
      flex-grow: 0;

      margin-left: 16px;

      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: var(--analytics-violet);
    }
  }
}
