.history-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: auto;
  width: auto;
  min-width: 42px;
  max-width: 42px;
  margin: auto;

  &__thumbnail {
    width: 42px;
    height: 42px;
    min-height: 42px;

    margin: 2px 0;

    border-radius: 8px;
  }

  &__details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 12px;

    padding: 2px;
  }

  &__icon {
    width: 12px;
    height: 12px;

    margin-right: 2px;
  }

  &__title {
    max-width: 42px;
    height: 24px;

    font-size: 0.5rem;
    font-weight: 500;
    text-align: center;
    white-space: normal;

    text-overflow: ellipsis;
    overflow: visible;

    word-break: break-word;
  }
}
