.navbar {
  display: flex;
  justify-content: center;

  height: 80px;
  min-height: 80px;
  max-height: 80px;
  width: 100%;

  background: linear-gradient(
    to right,
    var(--background-color) 50%,
    var(--secondary-color) 50%
  );

  z-index: 10;
}

.navbar-container {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  max-width: 1286px;

  background-color: var(--primary-color);
}

.white-decoration {
  position: absolute;

  top: 0;
  left: 0;
  width: 0;
  height: 0;

  z-index: 11;

  &.square {
    width: 50px;
    height: 80px;

    background-color: var(--background-color);
  }

  &.triangle {
    left: 49px;

    border-top: 80px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 40px solid var(--background-color);
  }
}

.orange-decoration {
  position: absolute;

  top: 0;
  right: 0;
  width: 0;
  height: 0;

  z-index: 11;

  &.square {
    width: 80px;
    height: 80px;

    background-color: var(--secondary-color);
  }

  &.triangle {
    right: 79px;

    border-top: 0 solid transparent;
    border-bottom: 80px solid transparent;
    border-right: 40px solid var(--secondary-color);
  }
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  flex-direction: row;
  flex: 1;

  height: 100%;

  margin: 0 32px;

  z-index: 12;
}

.nav-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  flex: 1;
}

.nav-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.locale {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
}

.btn-lang-analytics {
  position: relative;
  height: 48px;
  width: 64px;
  overflow: visible;
  border: none;
  background-color: transparent;

  img {
    max-height: 24px;
    max-width: 24px;
  }
}

.menu-user-lang {
  position: absolute;
  top: 100%;
  right: 10px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  padding: 8px 0;

  background-color: white;
  border-radius: 5px;
  border: 1px solid black;

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-user-lang > li {
  padding: 8px;
  white-space: nowrap;
}

.nav-right > .nav-item {
  width: min-content;
  white-space: pre-wrap;

  margin-left: 0px;

  padding: 6px 6px;

  text-decoration: none;
  font-weight: 600;

  text-align: center;

  border-radius: 10px;

  color: white;
  background-color: var(--analytics-violet);
}

.nav-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin-left: 10px;
  padding: 5px;

  border-radius: 50%;
  background-color: transparent;

  img {
    height: 38px;
    width: 38px;
  }
}

.nav-left > .nav-item {
  // margin-left: 48px;
  margin-top: 24px;
  margin-bottom: 4px;
  padding: 8px 32px;

  text-decoration: none;
  font-weight: 600;

  color: var(--text-color-white);

  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: var(--tertiary-color);
  }
}

//nav_item active
.nav-item.active {
  background-color: var(--secondary-color);

  &:hover {
    cursor: pointer;
  }
}

.nav-item:first-child {
  margin-left: 0px;
}

.nav-logo {
  min-width: 200px;
  max-width: 200px;

  margin-left: 32px;

  z-index: 12;
}

.nav-logo img {
  width: 100%;
  height: 100%;
}

.menu {
  display: none;
}

button:hover + .menu {
  display: block;
}

.menu {
  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 1;

  width: 102px;

  padding: 8px 0;
  margin-top: 29px;

  border: 1px solid black;
  border-radius: 0 0 5px 5px;

  font-size: 14px;
  color: black;
  background-color: white;
}

.menu-item {
  padding: 8px 4px;
  text-decoration: none;
}

.menu-item:hover {
  background-color: lightgray;
}

.nav-burger {
  display: none;
}

@media (max-width: 980px) {
  .nav-burger {
    display: initial;
    padding-top: 10px;
    padding-left: 10px;
    z-index: 20;
    padding-bottom: 8px;

    width: 175px;
  }

  .nav-left-burger :hover {
    background-color: lightgray;
  }

  .nav-left-burger {
    position: absolute;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    /* height: 50vh; */
    width: 200px;

    margin-top: 25px;
    margin-left: -8px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    color: white;
    background-color: #ffd700;
  }

  .nav-item-burger {
    width: 100%;

    margin-left: 0px;
    padding: 24px 0;

    text-align: center;

    border: 1px black solid;

    text-decoration: none;

    text-decoration: none;
    font-weight: 600;

    color: var(--text-color-white);
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: var(--tertiary-color);
    }
  }

  .nav-menu {
    margin: 0;
  }

  .nav-item-burger:last-child {
    border-bottom-right-radius: 10px;
  }

  .nav-left {
    display: none;
  }

  .nav-right > .nav-item {
    padding: 6px 8px;

    font-size: 12px;

    margin-right: 6px;
  }

  .navbar {
    justify-content: space-between;
  }

  .nav-logo {
    flex: 1 1;
    max-width: 200px;
    position: initial;

    margin-left: 4px;
  }
}

@media (max-width: 400px) {
  .nav-logo {
    margin-left: 16px;
  }
}

@media (max-width: 365px) {
  .nav-logo {
    margin-left: 4px;
  }
}
