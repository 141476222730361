// progress-skill-table.scss

.progress-skill-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.8rem;

  th,
  td {
    padding: 2px 8px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    vertical-align: middle;

    img {
      width: 16px;
      height: 16px;
    }
  }

  th {
    border-bottom: 2px solid #d0d0d0;
  }

  &__row {
    &:hover {
      background-color: #f9f9f9;
      transition: background-color 0.2s ease-in-out;
    }
  }
}
