.content-page--legal {
  width: 80%;
  max-width: 720px;
  line-height: 1.4;
  margin: 24px auto 64px auto;
  background-color: var(--background-color);
  color: var(--text-color-black);
}

.content-page--legal h1,
.content-page--legal h2,
.content-page--legal h3 {
  margin-top: 24px;
  margin-bottom: 12px;
  text-align: center;
  color: var(--text-color-black);
}

.content-page--legal h1 {
  font-size: 28px;
}

.content-page--legal h2 {
  font-size: 24px;
  margin-top: 48px;
  margin-bottom: 24px;
}

.content-page--legal h3 {
  font-size: 18px;
}

.content-page--legal p {
  text-align: justify;
  margin-bottom: 16px;
}

.content-page--legal ul {
  margin-left: 20px;
  margin-bottom: 24px;
  padding-left: 20px;
  list-style-type: disc;
}

.content-page--legal li {
  margin-bottom: 10px;
}

.content-attribution-table {
  margin: 0 auto 24px auto;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.content-attribution-table th,
.content-attribution-table td {
  padding: 8px 12px;
  border-bottom: 1px solid var(--border-color);
}

.content-attribution-table th {
  background-color: var(--analytics-card-background);
  color: var(--text-color-black);
}

.load-more-button {
  display: block;
  margin: 16px auto;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: var(--text-color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: var(--secondary-color);
}

.content-page--legal a {
  color: var(--primary-color);
  text-decoration: none;
}

.content-page--legal a:hover {
  text-decoration: underline;
}
