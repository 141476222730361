.footer-public {
  height: 100%;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  color: var(--text-color-white);
  background-color: var(--primary-color);
  z-index: 0;
}

.footer-public-container {
  height: 100%;
  width: 100%;
  max-width: 1024px;
  margin: auto;
  z-index: 1;
}

.footer-public .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-public .col-md-6 {
  flex: 1;
  padding: 0 16px;
}

.copyright-col {
  text-align: left;
}

.links-col {
  text-align: right;
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.legal-pages {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
  }

  &:visited {
    color: inherit;
  }
}
