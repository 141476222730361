.analytics-settings-notifications {
  &--all-notifs {
    border: 1px var(--analytics-violet) solid;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .explanatory-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.4;
    max-width: 500px;
  }
}
