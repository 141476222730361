.menu-title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .menu-item {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      margin-top: 24px;
    }

    &:hover {
      .menu-link {
        color: var(--analytics-violet);
      }
    }

    .menu-link {
      display: flex;
      align-items: center;

      padding: 12px 18px 12px 0;

      color: #fff;
      font-size: 16px;
      text-decoration: none;

      @media (max-height: 680px) {
        font-size: 14px;
      }

      @media (max-height: 580px) {
        font-size: 12px;
      }
    }

    .menu-icon {
      width: 38px;
      height: 38px;
      margin-right: 10px;
      vertical-align: middle;

      @media (max-height: 680px) {
        width: 24px;
        height: 24px;
      }

      @media (max-height: 580px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.analytics-menu-home {
  display: flex;
  flex-direction: row;

  .burger-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 38px;
    height: 38px;
    padding: 0;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .menu-title {
    margin: auto;
    margin-left: 18px;
  }
}
