.navbar-user {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 150px;
  min-height: 192px;
  max-height: 192px;

  background: var(--primary-color);

  z-index: 10;
}

.navbar-user-container {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  max-width: 1286px;

  background-color: var(--primary-color);
}

.nav-user-menu {
  display: flex;
  align-items: flex-end;
  // demo website
  justify-content: flex-end;

  flex-direction: row;
  flex: 1;

  height: 100%;

  z-index: 12;
}

.nav-user-logo {
  min-width: 200px;
  max-width: 200px;

  margin-left: 0px;

  z-index: 12;
}

.nav-user-logo img {
  width: 100%;
  height: 100%;
}

@media (max-width: 980px) {
  .navbar-user {
    height: 130px;
    min-height: 130px;
    max-height: 130px;

    background: var(--background-color);

    z-index: 50;
  }
  .navbar-user.lite {
    height: 130px;
    min-height: 130px;
    max-height: 130px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .navbar-user-container {
    height: 100%;
    background: none;
    align-items: flex-start;
  }

  .nav-user-logo {
    display: none;
  }

  .nav-user-menu {
    display: none;
  }

  .nav-user-menu-mobile {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    width: 66%;

    padding: 24px;

    border-top-left-radius: 0;
    border-top-right-radius: 3%;
    border-bottom-left-radius: 3%;
    border-bottom-right-radius: 3%;

    background-color: var(--analytics-violet);
  }

  .nav-user-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    border-bottom: 1px #00000026 solid;
  }

  .nav-user-burger {
    height: fit-content;

    padding: 24px;

    .burger-btn {
      background: none;
      border: none;
      cursor: pointer;
      width: 38px;
      height: 38px;
      padding: 0;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .nav-user-disconnect {
    height: fit-content;

    padding: 24px;

    .logout-btn {
      background: none;
      border: none;
      cursor: pointer;
      width: 38px;
      height: 38px;
      padding: 0;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
