.download-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 160px);
  width: 100%;
  background-color: #ffffff;

  @media (max-width: 480px) {
    min-height: calc(100vh - 192px);
  }
}

.download-container {
  max-width: 600px;
  margin: clamp(10px, 5vh, 40px) auto;
  padding: clamp(10px, 2vw, 20px);
  text-align: center;
}

.download-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary-color, #333);
}

.download-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #666;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 2rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
}

.app-android,
.app-ios {
  width: auto;
  height: clamp(80px, 10vw, 140px);
  max-width: 320px;
  cursor: pointer;
  object-fit: contain;
}

@media (max-width: 480px) {
  .app-android,
  .app-ios {
    height: 100px;
    max-width: 80%;
    object-fit: contain;
  }
}
