.search-analytics__searchbar {
  width: 80%;
  align-self: center;
  margin-top: 1rem;

  input {
    width: 100%;
    height: 24px;

    border: 2px solid var(--analytics-violet);
    border-radius: 15px;

    text-align: center;
    font-weight: bold;
  }
}

@media (min-width: 980px) {
  h2 {
    color: rgb(130, 61, 144);
    width: 100%;
    text-align: center;
  }

  .search-analytics__searchbar {
    width: 60%;
    margin: auto;
  }
}
