.reward-container {
  width: 100%;
  overflow: hidden;
  height: 112px;
  user-select: none;
}

.reward-container__scroll {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  cursor: grab;
}

.reward-container__scroll::-webkit-scrollbar {
  height: 8px;
}

.reward-container__scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.reward-item {
  flex: 0 0 auto;
  height: 100%;
  margin: 0 8px;
  text-align: center;
  user-select: none;
  white-space: normal;
}

.reward-item img {
  width: 100%;
  height: 80%;
  object-fit: contain;
  border-radius: 8px;
  pointer-events: none;
}

.reward-item p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  height: 20%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-transform: capitalize;
  word-break: keep-all;
  overflow-wrap: break-word;
}


.no-rewards-text {
  text-align: center;
  color: #666;
  padding: 10px;
}
