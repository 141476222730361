.settings-container {
  display: flex;
  flex-direction: column;

  flex: 1 1;
  width: 80%;

  margin: 72px auto;
  padding: 48px;
  box-sizing: border-box;

  border: 2px solid red;
  background-color: #fff;

  h1 {
    align-self: center;
    margin-bottom: 24px;
  }
}

.select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    flex: 1 1;
    width: 100%;
    margin: 0 8px;
  }
}

@media (max-width: 780px) {
  .select-container {
    flex-direction: column;
  }
}

.form-container {
  > div:nth-child(2) {
    margin-top: 64px;
  }
}
