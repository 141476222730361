.screentime {
  &__header {
    &--dropdown {
      .Dropdown-control {
        padding: 4px 25px 4px 10px !important;

        font-weight: bold;

        border: none;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}
