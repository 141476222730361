.single-form-container {
  display: flex;
  align-items: center;

  margin: 24px 0;

  label {
    flex: 1;

    max-width: 256px;

    margin: 4px 8px;
  }

  input {
    flex: 1;

    width: 97%;

    margin: 8px;
    padding: 8px 4px;

    border: 1px solid #707070;
    border-radius: 8px;

    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }
  }
}

@media (max-width: 780px) {
  .single-form-container {
    flex-direction: column;
    align-items: flex-start;

    * {
      margin: 8px 0;
    }
  }
}
