.single-select-container {
  display: flex;
  flex-direction: column;

  margin: 24px 0;

  label {
    flex: 1;

    max-width: 256px;
  }

  select {
    flex: 1;

    margin: 8px 0;
    padding: 8px 4px;

    border: 1px solid #707070;
    border-radius: 8px;

    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }

    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .single-select-container {
    flex-direction: column;

    * {
      margin: 8px 0;
    }
  }
}
