/* General Styles */
.custom-editor-container {
  width: 100%;
  height: 100vh; /* Full screen height */
  display: flex;
  flex-direction: column;

  /* Additional desktop styles */
}

/* Mobile styles */
.custom-editor-container.mobile {
  flex-direction: column-reverse; /* Tools at the bottom, canvas at the top */
  height: auto; /* Adjust based on content */

  .filerobot-image-editor {
    /* Adjust editor styles for mobile */
  }
}
