.auth {
  flex: 1;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 435px;
    max-width: 435px;
    margin: 10vh auto;
    padding: 30px 15px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;
    background-color: #e4e4e4;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__input {
    width: 97%;
    margin: 8px 0;
    padding: 8px 4px;
    border: 1px solid #707070;
    border-radius: 8px;
    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }
  }

  &__error-message {
    font-size: 0.8rem;
    color: rgb(219, 0, 0);
  }

  &__submit-button {
    width: 100%;
    margin: 24px auto 0 auto;
    padding: 12px;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin: 20px auto;

    button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      border-radius: 8px;
      background-color: #ccc;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--primary-color);
        color: white;
      }

      &.active {
        background-color: var(--primary-color);
        color: white;
      }
    }
  }

  &__work-in-progress {
    margin-top: 20px;
    text-align: center;
  }

  &__server-response {
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    color: black;
    font-size: 1rem;

    &.error-border {
      border: 2px solid red;
    }
  }

  &__info-paragraph {
    margin: 16px 0;
    font-size: 0.8rem;
    color: grey;
  }

  @media (max-width: 980px) {
    &__container {
      width: calc(100% - 24px);
      max-width: 288px;
      margin: 7vh auto;
    }
  }
}
