.settings-analytics__submit {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    width: auto;
    padding: 0.5rem 2rem;

    border-radius: 5px;
    border: 2px solid white;

    color: var(--text-color-white);
    background-color: var(--analytics-violet);

    img {
      width: 24px;
      height: 24px;
    }
  }
}
