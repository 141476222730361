.screen-time-history {
  &__no-activity {
    display: flex;
    align-items: center;

    margin: 24px 0 0 0;

    img {
      width: 16px;
      height: 16px;

      margin-right: 8px;
    }

    &--text-intro {
      font-size: 0.8rem;
      font-weight: bold;
    }

    &--text-date {
      font-size: 0.8rem;
    }
  }

  &__footer {
    margin: 24px 0 0 0;
    text-align: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }
}
