.content-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  width: 100%;
}

.content-page > h1 {
  margin: 32px 0 0 0;
  text-align: center;

  color: var(--primary-color);
}

.about-presentation {
  background-image: url(../../resources/images/city_04.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 500px;
  width: 100%;

  margin: 0 auto 64px auto;

  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px #000000;

  position: relative;

  &:before {
    content: "";
    position: absolute;

    top: 0px;
    left: 0;

    height: 500px;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.5);
  }
}

.about-presentation-text {
  position: absolute;
  top: 48px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
}

.about-presentation-title {
  display: flex;
  flex-direction: column;
}

.about-presentation-title > h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0 0 24px 0;
}

.about-presentation-title > h2 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 24px 0;
  color: white;
}

.about-presentation-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about-presentation-list > li {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0 24px 0;

  text-align: left;
}

.about-presentation-list li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}

.about-presentation-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid yellow;
}

.about-presentation-list li:nth-child(2)::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid greenyellow;
}

.about-presentation-list li:nth-child(3)::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid rgb(255, 77, 77);
}

.about-presentation-list li:nth-child(4)::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #b468ff;
}

.about-presentation-list li:nth-child(5)::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid rgb(69, 202, 255);
}

.about-presentation-list li:nth-child(6)::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid orange;
}

.about-presentation-apps {
  position: relative;
  top: 150px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100px;
  width: auto;
  max-width: 1024px;
}

.app-android,
.app-ios {
  height: 92px;

  margin: 0 24px;

  cursor: pointer;
}

.about-presentation-avatar {
  position: relative;
  right: 50%;

  height: 200px;
  width: 200px;
}

.about-presentation-avatar > img {
  height: 100%;
  width: 100%;
}

.about-presentation-avatar > img:hover {
  transform: scale(1.1);
  transition: 0.1s;
}

.about-presentation-avatar > img:active {
  transform: scale(1.2);
  transition: 0.1s;
}

.section-title {
  text-align: center;

  font-size: 2.5rem;
}

.section-description {
  text-align: center;
}

.popular-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: auto;
  width: 100%;
  max-width: 1024px;

  margin: 32px 0;
}

.popular-games-contents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  width: 100%;
  margin: 20px auto;
}

.popular-games-contents img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.popular-games-contents .image-container {
  width: 225px;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Media queries
@media (max-width: 980px) {
  .about-presentation-avatar {
    height: 150px;
    width: 150px;
  }

  .about-presentation-apps {
    top: 175px;
  }

  .popular-games-contents .image-container {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 754px) {
  .about-presentation-avatar {
    display: none;
  }

  .about-presentation-text {
    width: 75%;
  }

  .about-presentation-apps {
    top: 250px;
    flex-direction: column;
  }

  .popular-games-contents .image-container {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 750px) {
  .about-presentation {
    height: 700px;
  }

  .about-presentation:before {
    height: 700px;
  }

  .about-presentation-apps {
    top: 260px;
  }
}

.about-presentation-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1024px;
}

@media (max-width: 1250px) {
  .about-presentation-avatar {
    width: 150px;
    height: 150px;

    right: 380px;
  }

  .about-presentation-apps {
    top: 175px;
  }
}

@media (max-width: 1120px) {
  .popular-games {
    width: 80%;
  }

  .popular-games-contents {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 910px) {
  .about-presentation-avatar {
    display: none;
  }

  .about-presentation-apps {
    top: 270px;
  }

  .popular-games {
    width: 60%;
  }

  .popular-games-contents {
    grid-template-columns: repeat(1fr);
  }
}

@media (max-width: 750px) {
  .popular-games {
    width: 80%;
  }
}

.how-to-presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: auto;
  width: 100%;
  max-width: 1024px;

  margin: 32px 20%;
}

.steps-section {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  width: 100%;

  padding: 12px;

  border-radius: 24px;
  box-sizing: border-box;

  background-color: #acdeff;
}

.step {
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  min-width: 250px;

  margin: 10px;
  padding: 20px;

  border-radius: 24px;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;

  border: 3px solid;
  border-radius: 50%;

  font-size: 42px;
  text-align: center;
  font-weight: bold;
  color: #fff;

  background-color: white;
}

.step:nth-child(1) {
  background-color: var(--primary-color); /* cyan */
}

.step:nth-child(2) {
  background-color: var(--analytics-violet); /* violet */
}

.step:nth-child(3) {
  background-color: var(--secondary-color); /* orange */
}

.step:nth-child(1) .step-number {
  background-color: white;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.step:nth-child(2) .step-number {
  background-color: white;
  color: var(--analytics-violet);
  border-color: var(--analytics-violet);
}

.step:nth-child(3) .step-number {
  background-color: white;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.step-title {
  font-size: 32px;
  font-weight: bold;
}

.step-description {
  margin-top: 24px;

  font-size: 16px;
}

.step-title,
.step-description {
  color: var(--text-color-white);
  text-align: center;
}

.step img {
  height: auto;
  width: 50%;

  margin-top: 24px;
}

@media (max-width: 768px) {
  .how-to-presentation {
    width: 80%;
  }

  .steps-section {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 80%;

    margin-bottom: 24px;
  }
}

.skill-presentation {
  background-image: url(../../resources/images/landing-page/children-rose.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: auto;
  width: 100%;
  max-width: 1024px;

  margin: 32px 0;
}

.skill-container {
  display: flex;
  flex-direction: column;

  padding: 24px;
  padding-left: 20%;
}

.top-box {
  margin: 8px 0;
  padding: 20px 0;

  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.bottom-container {
  display: flex;
  margin: 8px 0;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: 10px;
}

.left-top-box,
.left-bottom-box {
  flex: 1;
  display: flex;
  align-items: center;

  padding: 10px;

  font-size: 24px;
  color: var(--text-color-white);
  font-weight: bold;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-container > img {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .skill-container {
    padding-left: 5%;
  }

  .top-box,
  .left-top-box,
  .left-bottom-box,
  .right-container {
    margin: 8px 0;
  }

  .left-top-box,
  .left-bottom-box {
    font-size: 18px;
  }

  .bottom-container {
    flex-direction: column;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.data-protection-presentation {
  height: auto;
  width: 100%;
  max-width: 1024px;
  margin: 32px 0;
}

.data-protection-description {
  height: auto;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 24px;
}

.data-protection-container {
  display: flex;
}

@media (max-width: 768px) {
  .data-protection-presentation {
    width: 80%;
  }

  .data-protection-container {
    flex-direction: column;
  }
}