.skill-map-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__button-container {
    display: flex;
    align-items: center;
  }

  &__button-wrapper {
    position: relative;
    cursor: pointer;

    &--small {
      width: 10%;
    }

    &--medium {
      width: 30%;
    }

    &__label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 0.43rem;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 10px);
      text-align: center;
    }

    &__image {
      width: 100%;
      height: auto;

      &--active {
        filter: saturate(1.5);
      }
    }
  }
}
