.rewards-progress {
  font-size: 13px;
  font-weight: bold;
  color: #823d90;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics__stats-content--double-card {
  display: flex;

  .detail-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__left,
  &__right {
    width: 48%;
  }
}

.gold-balance-content,
.city-progress-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.gold-balance-content {
  font-size: 16px;

  &__coin {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
  }

  &__current-balance {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__earned {
    color: green;
  }

  &__used {
    color: red;
    margin: 5px 0;
  }
}

.city-progress-content {
  font-size: 16px;

  &__image {
    width: 100%;
    height: 108px;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 25px;
  }

  &__percentage {
    font-weight: bold;
    color: #823d90;
  }
}

.double-detail-card {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left,
  &__right {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .detail-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 4px 0;
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: var(--analytics-card-background);
    height: 100%;

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
    }

    &__icon-container--left,
    &__icon-container--right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      column-gap: 8px;
      max-width: 30%;
      z-index: 10;
    }

    &__title {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
      font-weight: bold;
    }

    &__content {
      margin: 10px;
      flex-grow: 1;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media (min-width: 980px) {
  .analytics__stats-content--double-card {
    width: 48%;
    margin: 1%;
  }
}