.skill-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__name {
    display: flex;
    margin-bottom: 2px;

    &--title {
      font-size: 0.9rem;
    }

    &--count {
      font-size: 0.75rem;
      align-self: flex-end;
      margin-left: 4px;
    }
  }

  &__progress-bar {
    flex: 1;
    color: black;
  }

  &__label {
    flex-basis: 10%;

    margin-left: 8px;
    color: black;
  }

  &__button {
    flex-basis: 10%;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    margin-left: 8px;

    &-img {
      width: 50%;
      height: 50%;
    }
  }
}
