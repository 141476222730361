.history-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #00000008;

  &__head {
    border: none;
  }

  &__foot {
    border: none;
    text-align: center;
    padding-top: 8px;
  }

  &__row {
    border-bottom: 1px solid #ccc;

    td {
      border-right: 1px solid #ccc;
      padding: 4px;
      &:last-child {
        border-right: none;
      }
    }
  }

  &__header {
    border: none;
  }

  &__foot {
    td {
      border: none;
    }
  }

  &__header {
    &--icon {
      width: 16px;
      height: 16px;
    }
  }

  &__header,
  &__cell {
    text-align: center;
    vertical-align: middle;
  }

  &__cell {
    &--activity {
      .activity-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .category-icon {
          padding: 0 4px;
          width: 16px;
          height: 16px;
        }

        &__title {
          display: flex;
          flex-direction: column;

          font-size: 0.8rem;
          font-weight: bold;
        }

        &__category {
          font-size: 0.65rem;
          color: #666;
        }
      }
    }

    &--time {
      text-align: center;
      vertical-align: middle;
    }

    &--status {
      text-align: center;
      vertical-align: middle;
    }

    &--goal {
      text-align: center;
      vertical-align: middle;
    }

    &--feedback {
      text-align: center;
      vertical-align: middle;

      img {
        width: 14px;
        height: 14px;

        padding: 0 1px;
      }
    }
  }
}

@media (max-width: 980px) {
  .history-table {
    &__row {
      td {
        padding: 2px;
      }
    }

    &__cell {
      &--activity {
        .activity-info-container {
          &__title {
            font-size: 0.6rem;
            font-weight: bold;
          }

          &__category {
            font-size: 0.65rem;
            color: #666;
          }
        }
      }
    }
  }
}
