.analytics {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;

  flex: 1 1;
  width: 100%;
  max-width: 1286px;

  margin: 192px auto 48px auto;

  box-sizing: border-box;

  &__menu {
    z-index: 10;

    width: 20%;
    padding: 20px;

    margin-top: -40px;

    // position: fixed;
    // top: 152px;

    background-color: var(--analytics-violet);
  }

  &__stats {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;

    width: calc(100% - 24px);

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      width: calc(100% - 24px);
      height: 100%;

      margin: 24px 0;

      &-space {
        display: flex;
        justify-content: flex-start;

        width: 33%;
      }

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 33%;

        font-size: 1.5rem;
        font-weight: bold;
        color: black;

        .icon-title {
          width: auto;
          height: 2.2rem;

          margin-right: 16px;
        }
      }

      &-date {
        display: flex;
        justify-content: flex-end;

        width: 33%;

        font-weight: bold;
        color: var(--analytics-violet);

        &--dropdown {
          .Dropdown-control {
            padding: 4px 25px 4px 10px !important;

            border: none;
          }
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      width: 100%;

      &--card {
        width: 48%;

        margin: 1%;
      }
    }
  }

  &__date-filter-button {
    display: flex;
    align-items: center;

    color: #823d90;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;

    background: none;
    border: none;

    cursor: pointer;
    transition: color 0.3s;
  }

  .triangle-icon {
    height: 0.7rem;
    width: auto;
    margin-left: 0.5rem;
  }
}

@media (max-width: 980px) {
  .analytics {
    justify-content: space-evenly;
    margin: 130px auto 48px auto;

    &.lite {
      margin: 100px auto 48px auto;
    }

    &__stats {
      &-header {
        flex-direction: column;

        margin: 16px auto;

        &-title {
          width: 100%;
        }

        &-date {
          margin-top: 16px;
          width: 100%;
        }
      }

      &-content {
        flex-direction: column;
        flex-wrap: nowrap;

        &--card {
          width: 100%;

          margin: 4px 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
